import { forEach, omit, includes } from "lodash";
import { SECTIONS, ELEMENTS } from "./story";

/* A LIST OF AVAILABLE BLOCKS IN DATO FOR THIS PARTICULAR STORY */
/* MAPS THEM TO EXISTING COMPONENTS OUTLINED IN STORY.JS */
const mapDatoKeysToISF = {
  DatoCmsParagraph: "Paragraph",
  DatoCmsQuote: "Quote",
  DatoCmsImage: "Images",
  DatoCmsHighlight: "Highlight",
  DatoCmsGallery: "Gallery",
  DatoCmsVideo: "Video",
  DatoCmsImagetext: "ImageText",
  DatoCmsListItem: "ListItem",
  DatoCmsCta: "CTA",
  DatoCmsKeyValuePair: "KeyValuePair"
};

export const datoToBF = ({ content }) => {
  let storyContent = [];
  let currentLayout = null;

  forEach(content, (item, index) => {
    // check if first layout, and if so, init
    const t = item.__typename;
    if (includes(t, "Section")) {
      if (currentLayout === null) {
        currentLayout = 0;
      } else {
        currentLayout = currentLayout + 1;
      }
      let layout = {};

      /* Map section divider to existing sections - is there a more elegant way? */
      if (includes(item.sectionId, "intro")) {
        layout.c = SECTIONS.Intro;
      } else if (includes(item.sectionId, "history")) {
        layout.c = SECTIONS.History;
      } else if (includes(item.sectionId, "watches")) {
        layout.c = SECTIONS.Watches;
      } else if (includes(item.sectionId, "influencers")) {
        layout.c = SECTIONS.Influencers;
      } else if (includes(item.sectionId, "ambassador")) {
        layout.c = SECTIONS.Ambassador;
      } else if (includes(item.sectionId, "contentCrew")) {
        layout.c = SECTIONS.ContentCrew;
      } else if (includes(item.sectionId, "gallery")) {
        layout.c = SECTIONS.Gallery;
      } else if (includes(item.sectionId, "outro")) {
        layout.c = SECTIONS.Outro;
      } else if (includes(item.sectionId, "cities")) {
        layout.c = SECTIONS.Cities;
      } else {
        console.log(
          `No available section type for ${item.sectionId}. Have a look in utilities/dato.js`
        );
        return;
      }

      layout.data = omit(item, ["id", "__typename", "sectionId"]);
      layout.elements = [];
      layout.dataset = {};
      storyContent.push(layout);
    } else {
      if (index === 0) {
        console.log("Add an intro section divider bitte!");
      }

      if (mapDatoKeysToISF[t] === 'KeyValuePair') {
        storyContent[currentLayout].dataset[item.key] = item.content;
        return;
      }

      if (!ELEMENTS[mapDatoKeysToISF[t]]) {
        console.log(
          `No available element type for ${mapDatoKeysToISF[t]}. Have a look in utilities/dato.js`
        );
        return;
      }

      let element = {};
      element.type = mapDatoKeysToISF[t];
      element.c = ELEMENTS[element.type];
      element.data = omit(item, ["id", "__typename"]);
      storyContent[currentLayout].elements.push(element);
    }
  });

  return storyContent;
};

export default datoToBF;
