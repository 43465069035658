import React from "react";
import { graphql } from "gatsby";
import datoToBF from "@utilities/dato";
import Story from "@components/story/Story";
import Layout from "@components/layout/Layout";
// import HeroPlaceholder from "@components/Hero";
// import Intro from "containers/intro/Intro";
// import Watches from "containers/watches/Watches";
// import History from "containers/history/History";
// import Influencers from "containers/influencers/Influencers";
// import Ambassador from "containers/ambassador/Ambassador";
// import ContentCrew from "containers/contentCrew/ContentCrew";
// import Gallery from "containers/gallery/Gallery";
// import Outro from "containers/outro/Outro";

const IndexPage = ({ data }) => {
  const storyContent = data.allDatoCmsBespokeStory.edges[0].node.content;
  const content = datoToBF({
    content: storyContent,
  });

  return (
    <Layout>
      <Story content={content} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query StoryQuery {
    allDatoCmsBespokeStory(
      filter: { id: { eq: "DatoCmsBespokeStory-96325398-en" } }
    ) {
      edges {
        node {
          id
          title
          __typename
          heroImage {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
          content {
            ... on DatoCmsSectionDivider {
              id
              __typename
              sectionId
              sectionTitle
              headerImage {
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
              headerImageMobile {
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
            }
            ... on DatoCmsKeyValuePair {
              id
              __typename
              key
              content {
                ... on DatoCmsParagraph {
                  __typename
                  text
                }
                ... on DatoCmsJsonInput {
                  __typename
                  json
                }
                ... on DatoCmsImage {
                  id
                  __typename
                  items {
                    alt
                    fluid {
                      src
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
            }
            ... on DatoCmsParagraph {
              id
              __typename
              text
            }
            ... on DatoCmsQuote {
              id
              __typename
              text
              source
            }
            ... on DatoCmsVideo {
              id
              __typename
              providerUid
            }
            ... on DatoCmsImage {
              id
              __typename
              items {
                alt
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
            }
            ... on DatoCmsGallery {
              id
              __typename
              items {
                alt
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
            }
            ... on DatoCmsImagetext {
              id
              __typename
              image {
                alt
                fluid {
                  src
                  srcSet
                  aspectRatio
                }
              }
              text
            }
            ... on DatoCmsCta {
              id
              __typename
              url
              text
            }
          }
        }
      }
    }
  }
`;
